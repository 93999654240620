import React from "react";

// Style
import "./App.css";

const App = () => {
  return (
    <div className="w-full h-screen flex flex-col poppins p-5">
      <div className="home-login flex poppins flex-col gap-5">
        <img
          src={require("./assets/logo.png")}
          alt="logo"
          className="w-1/2 h-1/2 mx-auto"
        />
        <p className="text-black pixel-font leading text-sm md:text-lg">
          Join Avalanche's first raid to earn platform. Send your PixelPop on a
          mission and raid tweets to earn $AVAX rewards.
        </p>
        <div className="flex flex-col">
          <div className="flex w-full">
            <div
              className={
                "flex w-full cursor-pointer pixel-border bg-[#111629] pl-2"
              }
              onClick={() =>
                window.open(
                  "https://pixel-pops.gitbook.io/pixel-pops-whitepaper",
                  "_blank"
                )
              }
            >
              <p className="m-auto pixel-font text-sm p-2">WHITEPAPER</p>
            </div>
          </div>
          <div className="flex w-full mt-5">
            <div
              className={
                "flex w-full cursor-pointer pixel-border bg-[#1DA1F2] pl-2"
              }
              onClick={() =>
                window.open("https://twitter.com/PixelPopsWorld", "_blank")
              }
            >
              <p className="m-auto pixel-font text-sm p-2">TWITTER</p>
            </div>
          </div>
          <div className="flex w-full mt-5">
            <div
              className={
                "flex w-full cursor-pointer pixel-border bg-[#5865F2] pl-2"
              }
              onClick={() =>
                window.open("https://discord.gg/bvk7xN7fUr", "_blank")
              }
            >
              <p className="m-auto pixel-font text-sm p-2">DISCORD</p>
            </div>
          </div>
          <div className="flex w-full mt-5">
            <div
              className={
                "flex w-full cursor-pointer pixel-border bg-[#DB544E] pl-2"
              }
              onClick={() =>
                window.open(
                  "https://joepegs.com/collections/avalanche/pixel-pops",
                  "_blank"
                )
              }
            >
              <p className="m-auto pixel-font text-sm p-2">JOEPEGS</p>
            </div>
          </div>
          <div className="flex w-full mt-5">
            <div
              className={
                "flex w-full cursor-pointer pixel-border bg-[#B20719] pl-2"
              }
              onClick={() =>
                window.open(
                  "https://salvor.io/collections/0x45cc240d114e421d727ba0bf9dae4155c9b9d2eb",
                  "_blank"
                )
              }
            >
              <p className="m-auto pixel-font text-sm p-2">SALVOR.IO</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
